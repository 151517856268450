import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import createAuthRequest from './utils/createAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';

export const OlxRedirect = () => {
  const navigate = useNavigate();

  const query = useQuery({
    queryKey: ['olx-auth/token'],
    queryFn: () => {
      try {
        return createAuthRequest(`olx-auth/token${window.location.search}`, 'GET')();
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
  });

  useEffect(() => {
    if (query.isSuccess) {
      navigate('/');
      navigate(0);
    }
  }, [query.isSuccess, navigate]);

  return <CircularProgress />;
};