import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import { Listing, OlxStatus } from './models/listing';

interface DeleteListingDialogProps {
  open: boolean;
  listing: Listing;
  onClose: (deletionParameters?: { listing: Listing, shouldDeleteOlxListing: boolean }) => void;
}

export const DeleteListingDialog = ({ open, onClose, listing }: DeleteListingDialogProps) => {
  const [shouldDeleteOlxItem, setShouldDeleteOlxItem] = useState(false);

  const deleteInVendora = listing.crossListings?.vendora &&
    <Typography>При изтриване на обявата от expanda, свързаната обява във Vendora ще бъде изтрита перманентно. Операцията ще отнеме до 24ч.</Typography>;

  const handleOlxDeletionChoiceChange = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
    if (value !== null) {
      setShouldDeleteOlxItem(value);
    }
  };

  const isOlxListingDeletable = listing.externalIdentifiers?.olx
    && listing.crossListings?.olx?.status === OlxStatus.Active
    || listing.crossListings?.olx?.status === OlxStatus.New
    || listing.crossListings?.olx?.status === OlxStatus.Limited;

  const deleteInOlx = isOlxListingDeletable && <Stack width='100%' direction='column' spacing={1}>
    <Typography>Да деактивираме ли обявата и в OLX?</Typography>
    <ToggleButtonGroup
      value={shouldDeleteOlxItem}
      exclusive
      onChange={handleOlxDeletionChoiceChange}
    >
      <ToggleButton sx={{ width: '20%' }} value={true} aria-label="yes">
        Да
      </ToggleButton>
      <ToggleButton sx={{ width: '20%' }} value={false} aria-label="yes">
        Не
      </ToggleButton>
    </ToggleButtonGroup>
  </Stack>;

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Изтриване на обява</DialogTitle>
      <DialogContent>
        <Stack direction='column' alignItems='center' spacing={2}>
          {deleteInVendora}
          {deleteInOlx}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          Отмени
        </Button>
        <Button
          onClick={() => onClose({ listing, shouldDeleteOlxListing: shouldDeleteOlxItem })}
          variant="contained"
          color="error">
          Изтрий
        </Button>
      </DialogActions>
    </Dialog>
  );
};